import styled from 'styled-components';
import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import RenderAfterPageTransition from '../old/components/RenderAfterPageTransition';
import { breakpointUp } from '../styles/mediaUtils';
import Footer from '../old/components/Footer';
import SEO from '../new/components/SEO';

const ContentWrapper = styled.section`
  width: 100%;
  height: 100%;
`;

const StyledSection = styled.section`
  padding: 11rem 2rem 0;
  margin-bottom: 5rem;

  @media ${breakpointUp.xs} {
    padding-top: 16.5rem;
  }

  @media ${breakpointUp.md} {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-bottom: 5rem;
  }

  @media ${breakpointUp.lg} {
    padding-top: 19rem;
    max-width: 1376px;
    margin: 0 auto;
    margin-bottom: 5rem;
  }
`;

const StyledPageHeader = styled.div`
  width: 100%;
  line-height: 40px;
  letter-spacing: -0.29px;
  margin-bottom: 2rem;

  h2 {
    font-size: 22px;
  }

  @media ${breakpointUp.xs} {
    h2 {
      font-size: 32px;
    }

    letter-spacing: -0.37px;
    line-height: 48px;
  }

  @media ${breakpointUp.lg} {
    margin-bottom: 2.5rem;

    h2 {
      font-size: 32px;
    }
  }
`;

const StyledHeader = styled.div`
  margin: 3.25rem 0 2.5rem;

  h2 {
    font-size: 20px;
  }

  @media ${breakpointUp.xs} {
    h2 {
      font-size: 25px;
      width: 100%;
    }

    margin-top: 4rem;
    width: 100%;
  }

  @media ${breakpointUp.lg} {
    height: 45px;
    width: 100%;
    color: #000;
    letter-spacing: -0.29px;
    line-height: 45px;
    margin-top: 4.75rem;

    h2 {
      font-size: 25px;
    }
  }
`;

const StyledTextCol = styled.div`
  color: #53585f;
  font-size: 19px;
  height: auto;
  letter-spacing: -0.22px;
  line-height: 28px;
  margin-bottom: 1.05rem;

  @media ${breakpointUp.xs} {
    width: 524px;
    font-size: 20px;
    letter-spacing: -0.23px;
    line-height: 32px;
    margin-bottom: initial;
  }

  @media ${breakpointUp.lg} {
    width: 850px;
  }
`;

const StyledList = styled.div`
  ul {
    color: turquoise;
    font-size: 15px;
    margin: 0 0 3rem -1.2rem;

    @media ${breakpointUp.lg} {
      margin-left: -1.5rem;
    }
  }

  li {
    line-height: 32px;
  }

  span {
    color: #52595f;
    font-size: 19px;
  }

  @media ${breakpointUp.xs} {
    ul {
      margin-bottom: initial;
    }

    span {
      margin-left: 0.2rem;
      line-height: 37px;
    }
  }

  @media ${breakpointUp.lg} {
    ul {
      font-size: 18px;
    }

    span {
      font-size: 20px;
      margin-left: 0.5rem;
      line-height: 37px;
    }
  }
`;

const StyledLongTextCol = styled(StyledTextCol)`
  margin-bottom: 5rem;

  p {
    margin: 2rem 0 1.5rem;
  }

  @media ${breakpointUp.xs} {
    p {
      margin: initial 0 2rem;
    }

    margin-bottom: initial;
  }
`;

const PageIntroduce = styled(StyledTextCol)`
  margin-bottom: 3rem;

  @media ${breakpointUp.xs} {
    margin-bottom: initial;
  }
`;

const CookiePolicy: FC<PageProps> = () => {
  return (
    <>
      <SEO title="Cookie Policy" />
      <RenderAfterPageTransition>
        <ContentWrapper>
          <StyledSection>
            <StyledPageHeader>
              <h2>Cookie policy</h2>
            </StyledPageHeader>
            <PageIntroduce>
              <p>
                When you visit pages on the Elsewhen website, we process
                information about that activity – including information like the
                pages you viewed, the device you were using, your IP address,
                cookie data and location.
              </p>
            </PageIntroduce>
            <StyledHeader>
              <h2>What is a cookie?</h2>
            </StyledHeader>
            <StyledTextCol>
              <p>
                A cookie is a small file that can be placed on your device that
                allows us to recognise and remember that you, or someone using
                the same device, has visited before. It is sent to your browser
                and stored on your computer or tablet or mobile device. When you
                visit our sites, we may collect information from you
                automatically through cookies or similar technology.
              </p>
            </StyledTextCol>
            <StyledHeader>
              <h2>Why do we use cookies or similar technology?</h2>
            </StyledHeader>
            <StyledTextCol>
              <p>
                We process this data for the purposes described in our policy,
                such as:
              </p>
              <StyledList>
                <ul>
                  <li>
                    <span>Who the emerging leaders are in crypto banking</span>
                  </li>
                  <li>
                    <span>The effective tactics being employed to win</span>
                  </li>
                  <li>
                    <span>
                      Where the strategic focus is for the different players
                    </span>
                  </li>
                  <li>
                    <span>How they onboard and service different segments</span>
                  </li>
                  <li>
                    <span>
                      Similarities and differences with retail banking
                    </span>
                  </li>
                </ul>
              </StyledList>
            </StyledTextCol>
            <StyledHeader>
              <h2>What types of cookie are we using?</h2>
            </StyledHeader>
            <StyledLongTextCol>
              <p>
                We use four types of cookies, which we describe in this section.
              </p>
              <p>
                Essential – cookies that are essential to provide you with
                services you have requested, for example to remember if you have
                opted in or out of cookies. If you set your browser to block
                these cookies, then these functions and services will not work
                for you. In particular, we won’t be able to save your
                preferences about cookies.
              </p>
              <p>
                Performance - cookies which measure how often you visit our site
                and how you use it. These are managed by Google Analytics and
                Hubspot. For example, we aggregate this data to see which of our
                pages are most frequently visited, and users in what location.
              </p>
              <p>
                Advertising – cookies that allow us to advertise to you on third
                party websites after you have visited our site. These are
                managed by social media companies Facebook and LinkedIn. We do
                not share your data with third party advertisers, only third
                parties that allow us to run our own advertising, and we do not
                combine data we have collected with the data collected by third
                parties.
              </p>
              <p>
                None of the cookies or tracking we use collect information such
                as your name, email address, postal address or phone number.
              </p>
            </StyledLongTextCol>
          </StyledSection>
          <Footer />
        </ContentWrapper>
      </RenderAfterPageTransition>
    </>
  );
};

export default CookiePolicy;
